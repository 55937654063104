import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageBody } from '@abyss/web/ui/PageBody';
import { Modal } from '@abyss/web/ui/Modal';
import { FileUpload } from '@abyss/web/ui/FileUpload';
import { TextInput } from '@abyss/web/ui/TextInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Table } from '@abyss/web/ui/Table';
import { options } from '@src/utils/constants';
import { read, utils, write } from 'xlsx';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button } from '@abyss/web/ui/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { useForm } from 'react-hook-form';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import * as XLSX from 'xlsx';
import { hostServer, indicator } from '../../utils/constants';
import { ReviewPopup } from '../ReviewPopup';
import { MessagePopup } from '../MessagePopup';
import { Loader } from '../Loader';

import { startLoader, showDialog, stopLoader } from '../../store/actions';

const serverUrl = hostServer.dev;

export const LandingPage = () => {
  const headers = [];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeAccountEmail = useSelector((state) => {
    return state.activeAccountEmail;
  });
  const activeAccountUserName = useSelector((state) => {
    return state.activeAccountUserName;
  });
  const selectedRowCounts = useSelector((state) => {
    return state?.selectedRowCount;
  });
  const [showCreateCompareModal, setShowCompareModal] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState('');

  const [headerRow, setHeaderRow] = useState(1);
  const [sheetJsonData, setSheetJsonData] = useState({});
  const [sheetColumns, setSheetColumns] = useState([]);
  const [displaySheetData, setDisplaySheetData] = useState(false);
  const [sheetDataHeaderRows, setSheetDataHeaderRows] = useState([]);
  const [sheetColumnsHeaderRow, setSheetColumnsHeaderRow] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [removePopup, setRemovepopup] = useState(false);
  const [openProcessingModal, setOpenProcessingModal] = useState(false);
  const [mappedData, setMappedData] = useState([]);
  const [isReview, setIsReview] = useState(false);
  const [deafultColumnList, setDeafultColumnList] = useState([]);
  const [selectedVal, setSelectedVal] = useState(selectedRowCounts || '1');
  const [rosterName, setRosterName] = useState('');
  const [successMessage, setDropDownValidation] = useState(false);
  const [errorMessage, setDataErrorMessage] = useState(false);
  const [tinValidation, setTinValidation] = useState(false);
  const [npiValidation, setNPIValidation] = useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [nameError, setRosterNameError] = useState(false);
  const checkDisable = !(sheetColumns && sheetColumns.length > 0);
  const [patientType, setpatientType] = useState('Outpatient');
  const [selectedOption, setSelectedOption] = useState(null);
  const [headersLengthCheck, setHeaderLengthCheck] = useState(0);

  const objectArrayVal = useSelector((state) => {
    return state;
  });

  const backButtonClick = useSelector((state) => {
    return state?.backButtonStatus;
  });
  const loading = useSelector((state) => {
    return state.loading;
  });

  const fileTypesAccepted = {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx',
      '.csv',
    ],
  };

  const sheetFileName = () => {
    console.log('uploadedFileName====', uploadedFileName);
    const d = new Date();
    const formatedData = `${
      d.getMonth() + 1
    }-${d.getDate()}-${d.getFullYear()}_${d.getHours()}_${d.getMinutes()}_${d.getSeconds()}`;
    if (uploadedFileName) {
      setUploadedFileName(uploadedFileName.trim());
      setUploadedFileName(uploadedFileName.replace(/ /g, '_'));
      return `${uploadedFileName}${'_'}${formatedData}`;
    }
  };

  const addColumnInRow = (jsonData) => {
    jsonData.map((item) => {
      item.UPDATEDBY = activeAccountEmail;
      item.FILENAME = sheetFileName();
    });
    console.log('finalSheetData', jsonData);
    return jsonData;
  };

  const processJsonToDisplaySheetData = (sheetData) => {
    const sliced = sheetData.slice(0, 20);
    setSheetDataHeaderRows(sliced);
  };

  const onFileChangeHandler = (e) => {
    if (!e || e.length === 0) {
      alert('Please upload a valid file!');
      setUploadedFileName('');
      return;
    }

    const ext = e[0]?.name.split('.').pop().toLowerCase();
    if (!['csv', 'xlsx', 'xls'].includes(ext)) {
      alert(
        'Only CSV, XLSX, and XLS files will be processed. Please upload a valid file!'
      );
      setUploadedFileName('');
      return;
    }

    setLoading(true);
    dispatch(startLoader());

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const lastColumnIndex = parseInt(
        localStorage.getItem('selectHeader'),
        10
      );
      console.log('headerRow===', lastColumnIndex);
      const XLSX = require('xlsx');

      const populateHeaders = (worksheet) => {
        const headerRowIndex = 0;
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cellAddress = XLSX.utils.encode_cell({
            r: headerRowIndex,
            c: C,
          });
          const cell = worksheet[cellAddress];
          headers.push(cell ? cell.v : `Column ${C + 1}`);
        }
      };
      populateHeaders(worksheet);
      console.log('Headers:', headers);
      console.log('Headers length:', headers.length);
      setHeaderLengthCheck(headers.length);

      // Remove rows up to the selected header row
      for (let selectIndex = 1; selectIndex < lastColumnIndex; selectIndex++) {
        deleteRow(worksheet, 0);
      }

      const json = utils.sheet_to_json(worksheet, {
        raw: true,
        defval: '',
        blankrows: false,
      });

      setSheetJsonData(json);
      processJsonToDisplaySheetData(json);
      console.log('json_data', json);

      if (json.length === 0) {
        setTimeout(() => {
          dispatch(stopLoader());
          setLoading(false);
        }, 5000);
        return;
      }

      const allKeys = Object.keys(json[0]);
      const cols = allKeys.map((item) => {
        return {
          key: item,
          name: item.trim().substring(0, 20),
        };
      });

      setSheetColumnsHeaderRow(cols);

      const colData = allKeys.map((item) => {
        return { value: item };
      });

      colData.sort((a, b) => {
        if (a.value < b.value) {
          return -1;
        }
        if (a.value > b.value) {
          return 1;
        }
        return 0;
      });

      setSheetColumns(colData);
      dispatch(stopLoader());
      setLoading(false);
    };

    reader.readAsArrayBuffer(e[0]);
  };

  const onReviewPopupClick = (e) => {
    e.preventDefault();

    setIsReview(true);
  };
  function ec(r, c) {
    return utils.encode_cell({ r, c });
  }
  /* istanbul ignore next */
  function deleteRow(ws, row_index) {
    const variable = utils.decode_range(ws['!ref']);
    for (let R = row_index; R < variable.e.r; ++R) {
      for (let C = variable.s.c; C <= variable.e.c; ++C) {
        ws[ec(R, C)] = ws[ec(R + 1, C)];
      }
    }
    variable.e.r--;
    ws['!ref'] = utils.encode_range(variable.s, variable.e);
  }
  const currentDate = Date.now();
  const formatDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(currentDate);

  // function keepSessionAlive() {
  //   fetch(serverUrl, {
  //     method: 'GET', // or 'POST' if required
  //     headers: {
  //       'Content-Type': 'application/json',
  //       // Add any other headers like authentication tokens if necessary
  //     },
  //   })
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       return console.log('Session kept alive:', data);
  //     })
  //     .catch((error) => {
  //       return console.error('Error keeping session alive:', error);
  //     });
  // }

  // setInterval(keepSessionAlive, 60000);

  const handleAxiosRequest = async (url, data) => {
    try {
      const response = await axios.post(url, { data });
      console.log(response);

      if (response.status === 200 && !response.data.error) {
        alert('File uploaded successfully!');
      } else {
        alert('An error occurred during the file upload.');
      }
    } catch (err) {
      if (
        err.response &&
        typeof err.response.data === 'string' &&
        err.response.data.includes('SQL compilation error')
      ) {
        alert('A SQL compilation error occurred. Please check your query.');
      } else {
        console.log(err);
        alert('An error occurred while uploading the file. Please try again.');
      }
    }
  };

  const uploadAutomationMDAData = () => {
    const updatedJson = addColumnInRow(sheetJsonData);
    handleAxiosRequest(`${serverUrl}/saveAutomationMDAColumnData`, updatedJson);
  };

  const uploadCirrusNetworkDetail = () => {
    const updatedJson = addColumnInRow(sheetJsonData);
    handleAxiosRequest(`${serverUrl}/saveCirrusNetworkDetail`, updatedJson);
  };

  const uploadEXCNDBMDADelta = () => {
    const updatedJson = addColumnInRow(sheetJsonData);
    handleAxiosRequest(`${serverUrl}/saveEXCNDBMDADelta`, updatedJson);
  };

  const uploadCategoryMappingFileData = () => {
    const updatedJson = addColumnInRow(sheetJsonData);
    handleAxiosRequest(
      `${serverUrl}/saveuploadCategoryMappingFile`,
      updatedJson
    );
  };

  const uploadNDBCirrusMappingsData = () => {
    const updatedJson = addColumnInRow(sheetJsonData);
    handleAxiosRequest(`${serverUrl}/saveNDBCirrusMappingsFile`, updatedJson);
  };

  const form = useForm({});

  const handleReset = () => {
    localStorage.setItem('selectHeader', 1);
    setHeaderRow(1);
    setSheetColumns([]);
    setUploadedFileName('');
    setSheetJsonData([]);
    dispatch({ type: 'FILE_UPLOAD_STATUS', value: false });
    setRosterName('');
    dispatch({ type: 'FILE_BACK_BUTTON_STATUS', value: true });
    localStorage.removeItem('rosterName');
    dispatch({
      type: 'SET_REVIEWED_ROW_VALUES',
      value: [],
    });
    setOpenProcessingModal(false);
  };

  const handleAcknowledge = (event) => {
    event.preventDefault();
    setDataErrorMessage(false);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <React.Fragment>
      <Backdrop data-testid="loading-1" open={isLoading}>
        <CircularProgress color="primary" />
      </Backdrop>
      <div
        style={{
          background: 'white',
        }}
      >
        <div
          style={{
            background: 'rgb(42, 87, 117)',
            height: '10px',
            width: '100%',
          }}
        />
        <div className="landing-page-header-wrapper">
          <div
            style={{
              width: '20%',
              paddingRight: '10px',
              // marginRight: '200px',
              // marginLeft: '100px',
              // marginTop: '5px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                // width: '50%',
                margin: '5px 5px 5px 20px',
              }}
            >
              <img
                style={
                  {
                    // position: 'absolute',
                  }
                }
                src="./UHG-Logo.png"
                alt="UHG Logo"
                width={50}
                height={50}
              />
            </div>
            <div
              style={{
                width: '50%',
                marginRight: '20px',
              }}
            >
              <a
                href="https://uhgazure.sharepoint.com/:p:/r/sites/PolarisProviderOperations1/Reporting_and_Technology/_layouts/15/Doc.aspx?sourcedoc=%7B20AD51BA-520F-4676-A6C4-AD94707592DA%7D&file=USP%20Provider%20Compare.pptx&action=edit&mobileredirect=true"
                target="_blank"
                style={{ color: 'rgb(0, 38, 119)' }}
                rel="noreferrer"
              >
                {' '}
                <img
                  style={{
                    // position: 'absolute',
                    display: 'flex',
                    cursor: 'pointer',
                  }}
                  src="./access-guide-link.png"
                  alt="access guide logo"
                  width={50}
                  height={50}
                />
              </a>
              <span
                style={{
                  fontSize: 'small',
                  color: 'rgb(0, 38, 119)',
                  fontWeight: '900',
                  display: 'flex',
                }}
              >
                User guide
              </span>
            </div>
          </div>
          <div
            style={{
              width: '85%',
              textAlign: 'center',
              marginRight: '5px',
              marginLeft: '5px',
              marginTop: '5px',
            }}
          >
            <h4>Automation Audit </h4>
          </div>
          <div
            style={{
              width: '5%',
              // marginRight: '100px',
              // marginLeft: '500px',
              marginTop: '5px',
              paddingRight: '200px',
            }}
          >
            <img
              style={{
                position: 'absolute',
              }}
              src="./UHC-logo.png"
              alt="UHC Logo"
              width={100}
              height={50}
            />
          </div>
        </div>
        <div
          style={{
            // textAlign: 'left',
            paddingLeft: '20px',
            display: 'flex',
            marginTop: '5px',
            alignItems: 'center',
          }}
        >
          <span>
            <img
              src="./queries.png"
              alt="Queries icon"
              width={30}
              height={30}
            />
          </span>
          <br />
          <span
            style={{
              color: '#002677',
              fontWeight: '900',
              marginLeft: '5px',
              fontSize: '12px',
            }}
          >
            <a
              href="https://uhgazure.sharepoint.com/sites/PolarisProviderOperations1/Reporting_and_Technology/Lists/USP%20Reporting%20%20Technology%20Intake%20Form/AllItems.aspx?viewpath=%2Fsites%2FPolarisProviderOperations1%2FReporting%5Fand%5FTechnology%2FLists%2FUSP%20Reporting%20%20Technology%20Intake%20Form%2FAllItems%2Easpx"
              target="_blank"
              style={{ color: 'rgb(0, 38, 119)' }}
              rel="noreferrer"
            >
              Any Queries/comments, please write to us
            </a>
          </span>
        </div>
        <div
          style={{
            textAlign: 'left',
            paddingLeft: '20px',
          }}
        >
          <span
            style={{
              color: '#002677',
              fontWeight: '900',
              fontSize: '14px',
            }}
          >
            Hello {activeAccountUserName} - Welcome to Automation Audit
          </span>
          <br />
          <span
            style={{
              color: '#002677',
              fontWeight: '900',
              fontSize: '13px',
            }}
          >
            User : {activeAccountEmail}
          </span>
          {uploadedFileName && (
            <span
              style={{
                color: 'rgb(0, 38, 119)',
                marginLeft: '22%',
                fontWeight: '900',
                fontSize: '25px',
              }}
            >
              {uploadedFileName}
            </span>
          )}
          <span
            style={{
              float: 'right',
              fontWeight: '700',
              color: 'rgb(0, 38, 119)',
            }}
          >
            {formatDate}
          </span>
        </div>
        <div
          style={{
            margin: '8px 0px',
            background: 'rgb(204 243 247)',
            height: '15px',
            width: '100%',
          }}
        />
        <PageBody>
          <Loader open={loading} />
          {openProcessingModal ? (
            <React.Fragment>
              {/* <MapColumns
                columns={sheetColumns}
                handleFinalSubmit={(e) => {
                  return onReviewPopupClick(e);
                }}
                handleReset={handleReset}
                mappedData={mappedData}
                uploadedFileName={uploadedFileName}
              /> */}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div
                style={{
                  width: '70%',
                  margin: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  height: '300px',
                }}
              >
                <section
                  style={{
                    cursor: 'pointer',
                    marginTop: '10px',
                  }}
                  onClick={() => {
                    setShowCompareModal(true);
                  }}
                >
                  <img
                    width="170"
                    height="170"
                    src="./comparet.png"
                    alt="Roster compare tool"
                  />
                  <img
                    max-width="500px"
                    width="70%"
                    height="200"
                    src="./displaybar.png"
                    alt="dislaybar"
                  />
                  <h6 style={{ fontSize: 'small' }}>Outpatient</h6>
                </section>
              </div>
              <Modal
                title="Upload File"
                isOpen={showCreateCompareModal}
                onClose={() => {
                  setShowCompareModal(false);
                  handleReset();
                }}
                css={{
                  'abyss-modal-content-title': {
                    fontSize: '24px!important',
                  },
                }}
              >
                <Modal.Section>
                  <FormProvider state={form}>
                    <SelectInput
                      label="Outpatient Category"
                      placeholder="Choose Category"
                      model="Outpatient"
                      isClearable
                      isSearchable
                      options={[
                        {
                          value: 'Automation',
                          label: 'Automation MDA Delta Maintenance',
                        },
                        { value: 'CNDG', label: 'Cirrus Network Detail Guide' },
                        {
                          value: 'ENMD',
                          label: 'EXC NDB MDA Delta Maintenance Trigger',
                        },
                        { value: 'CMF', label: 'Category Mapping File' },
                        { value: 'NDBCM', label: 'NDB to Cirrus Mapping' },
                        // { value: 'alpine', label: 'Alpine' },
                        // { value: 'ember', label: 'Ember' },
                        // { value: 'stimulus', label: 'Stimulus' },
                        // { value: 'preact', label: 'Preact' },
                      ]}
                      onChange={(x) => {
                        console.log('Selected option:', x);
                        setSelectedOption(x);
                      }}
                      validators={{ required: true }}
                    />
                  </FormProvider>
                  <FileUpload
                    maxMessage={false}
                    maxFiles={1}
                    label="Upload Outpatient File:"
                    // isDisabled={false}
                    isDisabled={!checkDisable}
                    fileTypes={fileTypesAccepted || ''}
                    onChange={(e) => {
                      // console.log(e);
                      if (e && e[0]) {
                        const fileName = e[0].name;
                        const fileNameSplit = fileName.split('.');
                        setUploadedFileName(fileNameSplit[0]);
                        onFileChangeHandler(e);
                      } else {
                        // Handle the case where no file is selected or the file is removed
                        setUploadedFileName('');
                        onFileChangeHandler([]);
                      }
                    }}
                    css={{
                      'abyss-file-upload-body-container': {
                        width: '70% !important',
                        justifyContent: 'space-around',
                        flexDirection: 'row',
                        height: '60px!important',
                        // padding: '5px',
                      },
                    }}
                  />
                  <div
                    style={{
                      marginTop: '10px',
                    }}
                  >
                    <TextInput
                      css={{
                        'abyss-text-input-root': {
                          marginTop: '5px',
                          width: '60%',
                        },
                      }}
                      label="Outpatient Filename:"
                      value={uploadedFileName}
                      onChange={(e) => {
                        setUploadedFileName(e.target.value);
                        setRosterNameError(false);
                      }}
                    />
                    {nameError && (
                      <span style={{ color: 'red' }}>
                        Please enter file name
                      </span>
                    )}
                    <SelectInput
                      isDisabled={
                        sheetJsonData &&
                        sheetJsonData.length &&
                        sheetJsonData.length > 0
                      }
                      label="Header Row:"
                      placeholder="Please select"
                      data-testid="selectInput"
                      isSearchable
                      value={headerRow}
                      css={{
                        'abyss-select-input-input-container': {},
                      }}
                      width="200px"
                      onChange={(e) => {
                        console.log('eeeeeeeeeeee', e);
                        localStorage.setItem('selectHeader', e);
                        setHeaderRow(e);
                      }}
                      options={options}
                    />
                    <div
                      style={{
                        marginTop: '10px',
                        fontWeight: '800',
                        opacity: '0.8',
                      }}
                    >
                      <span>Sheet Data: </span>
                      <Link
                        onClick={() => {
                          setDisplaySheetData(true);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        Preview
                      </Link>
                      <Button
                        disabled={Object.keys(sheetJsonData).length === 0}
                        onClick={() => {
                          console.log(
                            'Button clicked, selectedCategory:',
                            selectedOption
                          );
                          if (selectedOption === 'CNDG') {
                            console.log('Selected Option:', selectedOption);
                            console.log('Headers Length:', headersLengthCheck);
                            if (headersLengthCheck === 43) {
                              uploadCirrusNetworkDetail();
                            } else {
                              alert('Please upload the correct file.');
                            }
                          } else if (selectedOption === 'ENMD') {
                            console.log('Selected Option:', selectedOption);
                            console.log('Headers Length:', headersLengthCheck);
                            if (headersLengthCheck === 8) {
                              uploadEXCNDBMDADelta();
                            } else {
                              alert('Please upload the correct file.');
                            }
                          } else if (selectedOption === 'Automation') {
                            console.log('Selected Option:', selectedOption);
                            console.log('Headers Length:', headersLengthCheck);
                            if (headersLengthCheck === 23) {
                              uploadAutomationMDAData();
                            } else {
                              alert('Please upload the correct file.');
                            }
                          } else if (selectedOption === 'CMF') {
                            console.log('Selected Option:', selectedOption);
                            console.log('Headers Length:', headersLengthCheck);
                            if (headersLengthCheck === 11) {
                              uploadCategoryMappingFileData();
                            } else {
                              alert('Please upload the correct file.');
                            }
                          } else if (selectedOption === 'NDBCM') {
                            console.log('Selected Option:', selectedOption);
                            console.log('Headers Length:', headersLengthCheck);
                            if (headersLengthCheck === 8) {
                              uploadNDBCirrusMappingsData();
                            } else {
                              alert('Please upload the correct file.');
                            }
                          }
                        }}
                        style={{ float: 'right', marginBottom: '5px' }}
                      >
                        Submit
                      </Button>
                      <Modal
                        title="Sheet Data"
                        isOpen={displaySheetData}
                        onClose={() => {
                          setDisplaySheetData(false);
                        }}
                        size="full"
                      >
                        <Modal.Section>
                          <Table
                            title="Table Scroll"
                            columns={sheetColumnsHeaderRow}
                            rows={sheetDataHeaderRows}
                          />
                        </Modal.Section>
                      </Modal>
                    </div>
                  </div>
                </Modal.Section>
              </Modal>

              {showHistory && <React.Fragment />}
            </React.Fragment>
          )}
          {isReview && (
            <ReviewPopup
              open={isReview}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          )}
          {successMessage && (
            <MessagePopup
              open={successMessage}
              handleClose={handleCloseMSG}
              handleAcknowledge={handleAcknowledge}
              message="Submission Saved Successfully."
              errorName=""
              successCase
            />
          )}
          {errorMessage && (
            <MessagePopup
              open={errorMessage}
              handleClose={handleCloseMSG}
              handleAcknowledge={handleAcknowledge}
              message="Submission failed.  Please submit screenshot of error to R&T."
              errorName={errorNameMessage}
              errorCase="err"
            />
          )}
        </PageBody>
      </div>
    </React.Fragment>
  );
};
