import React from 'react';
import { Router } from '@abyss/web/ui/Router';
import { EventType, PublicClientApplication } from '@azure/msal-browser';

import { Home } from './Home';
import { LandingPage } from '../components/LandingPage/LandingPage';

export const Routes = () => {
  return (
    <Router.Routes title="MDA Upload">
      <Router.Route
        path="/"
        element={
          <Home component="Dashboard">
            <LandingPage />
          </Home>
        }
      />
    </Router.Routes>
  );
};
