import React, { useEffect, useState } from 'react';
import { styled } from '@abyss/web/tools/styled';
import { useSelector, useDispatch } from 'react-redux';
import { PageHeader } from '@abyss/web/ui/PageHeader';
import { Brandmark } from '@abyss/web/ui/Brandmark';
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { Login } from '@src/common/Login';
import { Navbar } from '../../components/Navbar';
import { Dashboard } from '../../components/Dashboard';
import { Footer } from '../../components/Footer';
import { LandingPage } from '../../components/LandingPage';
import { msalConfig } from '../../utils/authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

if (
  msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for Sign In and set Active Account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const { account } = event.payload;
    msalInstance.setActiveAccount(account);
  }
});

const HomeContainer = styled('div', {
  textAlign: 'center',
  // mineight: '100%';
  marginLeft: '60px',
  marginRight: '60px',
});

const MainContent = (props) => {
  const getAccoutDetails = useMsal();
  const dispatch = useDispatch();
  const currentAccountDetails = getAccoutDetails.instance.getAllAccounts()[0];
  const isFooter = useSelector((state) => {
    return state?.isFileUploaded;
  });

  const [hours] = useState(new Date().getHours());
  const [greeting, setGreeting] = useState('');

  useEffect(() => {
    if (hours < 12) {
      setGreeting('Good morning, ');
    } else if (hours >= 12 && hours < 16) {
      setGreeting('Good afternoon, ');
    } else if (hours >= 16) {
      setGreeting('Good evening, ');
    }
  }, [greeting, hours]);

  useEffect(() => {
    if (getAccoutDetails) {
      dispatch({
        type: 'SET_ACTIVE_ACCOUNT_EMAIL',
        value: currentAccountDetails?.username,
      });
      dispatch({
        type: 'SET_ACTIVE_ACCOUNT_USER_NAME',
        value: currentAccountDetails?.name,
      });
    }
  }, [getAccoutDetails, currentAccountDetails]);

  return (
    <React.Fragment>
      <MsalProvider instance={msalInstance}>
        <AuthenticatedTemplate>
          <HomeContainer>
          {props.component === 'Dashboard' && (
              <React.Fragment>
                {' '}
                {/* <Dashboard /> */}
                {/* <LandingPage /> */}
                {props.children}
                {!isFooter && <Footer />}{' '}
              </React.Fragment>
            )}
          </HomeContainer>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Login />
        </UnauthenticatedTemplate>
      </MsalProvider>
    </React.Fragment>
  );
};

export const Home = (props) => {
  return (
    <React.Fragment>
      <div
        style={{
          background: 'lightGray',
          marginLeft: '60px',
          marginRight: '60px',
        }}
      >
        <MsalProvider instance={msalInstance}>
          <MainContent component={props.component}>
            {props.children}
          </MainContent>
        </MsalProvider>
      </div>
    </React.Fragment>
  );
};

